// src/components/Sidebar.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import { HomeIcon, PlusIcon, ClipboardDocumentListIcon, Squares2X2Icon, FlagIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';
import useUserProfile from '../hooks/useUserProfile';
import axiosInstance from '../api/axiosConfig';

import { useQuery } from '@tanstack/react-query';
import logo from '../assets/img/logomambo.png'; // Asegúrate de cambiar esto a la ruta correcta de tu imagen

const fetchEventTypes = async () => {
  const { data } = await axiosInstance.get('/v1/event-types/');
  return data;
};

const Sidebar = () => {
  const { data: userProfile } = useUserProfile();
  const isApprover = userProfile?.es_aprobador;
  const { data: eventTypes = [], error, isLoading } = useQuery({
    queryKey: ['eventTypes'],
    queryFn: fetchEventTypes,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading event types</div>;
  }

  return (
    <div className="flex flex-col w-64 bg-white shadow-md">
      <div className="flex justify-center p-4">
        <img src={logo} alt="Mambo Compliance" className="h-16 w-30" />
      </div>
      <nav className="flex-1">
        <ul className="space-y-2">
          <li className="flex items-center px-4 py-2 hover:bg-gray-200">
            <NavLink to="/dashboard" exact className="flex items-center w-full" activeClassName="font-semibold">
              <HomeIcon className="w-5 h-5 mr-2 text-primary" />
              <span className="text-primary">Inicio</span>
            </NavLink>
          </li>
          {!isApprover &&(
            <li className="flex items-center px-4 py-2 hover:bg-gray-200">
              <NavLink to="/crear-evento" className="flex items-center space-x-2 text-gray-700" activeClassName="font-semibold">
                <PlusIcon className="w-5 h-5" />
                <span>Crear evento</span>
              </NavLink>
            </li>
          )}
          <li className="flex items-center px-4 py-2 font-bold text-black">
            <span>Listas de eventos</span>
          </li>
          {eventTypes.map(type => (
            <li key={type.id} className="flex items-center px-4 py-2 pl-6 hover:bg-gray-200">
              <NavLink to={`/tipo-eventos/${type.id}`} className="flex items-center w-full" activeClassName="font-semibold">
                <ClipboardDocumentListIcon className="w-5 h-5 mr-2 text-black" />
                <span>{type.nombre}</span>
              </NavLink>
            </li>
          ))}
          
           {isApprover && (
            <li className="flex items-center px-4 py-2 hover:bg-gray-200">
              <NavLink to="/kanban" className="flex items-center w-full" activeClassName="font-semibold">
                <Squares2X2Icon className="w-5 h-5 mr-2 text-black" />
                <span>Tablero Kanban</span>
              </NavLink>
            </li>
          )}
         
            <li className="flex items-center px-4 py-2 hover:bg-gray-200">
              <NavLink to="/estados" className="flex items-center w-full" activeClassName="font-semibold">
                <FlagIcon className="w-5 h-5 mr-2 text-black" />
                <span>Estados</span>
              </NavLink>
            </li>
         
          {isApprover && (
            <li className="flex items-center px-4 py-2 hover:bg-gray-200">
              <NavLink to="/configuraciones" className="flex items-center w-full" activeClassName="font-semibold">
                <Cog6ToothIcon className="w-5 h-5 mr-2 text-black" />
                <span>Configuraciones</span>
              </NavLink>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;

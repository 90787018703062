// src/hooks/useEventDetails.js
import { useQuery } from '@tanstack/react-query';
import axiosInstance from '../api/axiosConfig';

const fetchEventDetails = async (eventId) => {
  const { data } = await axiosInstance.get(`v1/events/${eventId}/`);
  return data;
};

const useEventDetails = (eventId) => {
  return useQuery({
    queryKey: ['eventDetails', eventId],
    queryFn: () => fetchEventDetails(eventId),
    keepPreviousData: true,
  });
};

export default useEventDetails;

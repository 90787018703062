import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import useUserProfile from '../hooks/useUserProfile';
import {NameInitialsAvatar} from 'react-name-initials-avatar';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

const ProfileDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: user, error, isLoading } = useUserProfile();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading user data</div>;

  return (
    <div className="relative">
      <button onClick={toggleDropdown} className="flex items-center px-3 py-2 space-x-2 bg-purple-100 rounded-full">
        <NameInitialsAvatar name={`${user.first_name} ${user.last_name}`} className="rounded-full" />
        <span className="text-sm">{user.first_name} {user.last_name} </span>
        <ChevronDownIcon className="w-5 h-5" />
      </button>
      {isOpen && (
        <div className="absolute right-0 z-50 w-48 mt-2 bg-white rounded-md shadow-lg">
          <div className="py-1">
            <NavLink to="/perfil" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Perfil</NavLink>
            <NavLink to="/configuraciones" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Configuraciones</NavLink>
            <NavLink to="/logout" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Cerrar Sesión</NavLink>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileDropdown;

// src/components/InfoCard.js
import React from 'react';
import { PlusCircleIcon } from '@heroicons/react/24/outline';

const InfoCard = ({ title, count, onTime, expired }) => {
  return (
    <div className="flex flex-col p-6 space-y-4 bg-white rounded-lg shadow-md">
      <div>
        <h3 className="text-xl font-semibold text-primary">{title}</h3>
        <p className="text-4xl font-bold text-primary">{count}</p>
      </div>
      <div className="flex items-center justify-between">
        <div className="space-y-2">
          <p className="flex items-center text-green-600">
            <span className="inline-block w-2 h-2 mr-2 bg-green-600 rounded-full"></span>
            {onTime} En tiempo
          </p>
          <p className="flex items-center text-red-600">
            <span className="inline-block w-2 h-2 mr-2 bg-red-600 rounded-full"></span>
            {expired} Vencidos
          </p>
        </div>

        <button className="inline-flex items-center px-5 py-3 text-base font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
          <PlusCircleIcon className="w-4 h-4 text-white me-2" />
          <span>Agregar</span>
        </button>
      </div>
    </div>
  );
};

export default InfoCard;

// src/components/Dashboard.js
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import axiosInstance from '../api/axiosConfig';
import InfoCard from './InfoCard';

const fetchEventStats = async () => {
    const { data } = await axiosInstance.get('/v1/event-types-stats/');
    console.log(data);
    return data;
};

const Dashboard = () => {
    const { data: eventStats = [], error, isLoading } = useQuery({
        queryKey: 'eventStats',
        queryFn: fetchEventStats,
    });

    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div>Error loading event stats</div>;
    }
  return (
    <div className="p-4 sm:p-8">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        {eventStats.map(stat => (
            <InfoCard key={stat.id} title={stat.tipo_evento} count={stat.total_eventos} onTime={stat.eventos_a_tiempo} expired={stat.eventos_vencidos} />
        ))}
      </div>
    </div>
  );
};

export default Dashboard;

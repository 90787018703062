// src/components/UserName.js
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import axiosInstance from '../api/axiosConfig';

const fetchUserName = async (userId) => {
  const { data } = await axiosInstance.get(`v1/auth/profile/${userId}/`);
  return data;
};

const UserName = ({ userId }) => {
  const { data, error, isLoading } = useQuery({
    queryKey: ['userName', userId],
    queryFn: () => fetchUserName(userId),
    keepPreviousData: true,
  });

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (error) {
    return <span>Error</span>;
  }

  return <span>{data.first_name} {data.last_name}</span>;
};

export default UserName;

import { useQuery } from '@tanstack/react-query';
import axiosInstance from '../api/axiosConfig';

const fetchNotifications = async () => {
  const { data } = await axiosInstance.get('/v1/notifications/');
  return data;
};

const useNotifications = () => {
  return useQuery({
    queryKey: ['notifications'],
    queryFn: fetchNotifications,
    keepPreviousData: true,
  });
};

export default useNotifications;

import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import {jwtDecode} from 'jwt-decode';
import axiosInstance from '../api/axiosConfig';
import { getEventTypes } from '../api/eventTypes';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'; // Importamos useNavigate

const CreateEvent = () => {
  const [eventTypes, setEventTypes] = useState([]);
  const [selectedEventType, setSelectedEventType] = useState('');
  const [formData, setFormData] = useState({
    nombre: '',
    descripcion: '',
    montoEstimado: '',
    fechaDelEvento: '',
    additional_fields: {}
  });
  const [additionalFields, setAdditionalFields] = useState([]);
  const [flyer, setFlyer] = useState(null);
  const navigate = useNavigate(); // Usamos useNavigate

  // Obtener el user_id del token JWT
  const token = localStorage.getItem('access'); // o donde almacenes tu token
  const decodedToken = jwtDecode(token);
  const userId = parseInt(decodedToken.user_id, 10);

  // Obtener tipos de evento al montar el componente
  useEffect(() => {
    const fetchEventTypes = async () => {
      try {
        const data = await getEventTypes();
        setEventTypes(data);
      } catch (error) {
        console.error('Error fetching event types:', error);
      }
    };

    fetchEventTypes();
  }, []);

  // Actualizar campos adicionales cuando se selecciona un tipo de evento
  useEffect(() => {
    const selectedType = eventTypes.find(type => type.id === parseInt(selectedEventType));
    setAdditionalFields(selectedType ? selectedType.campos_adicionales : []);
  }, [selectedEventType, eventTypes]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Actualizar campos adicionales en formData
  const handleFieldChange = (e, field) => {
    const { value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      additional_fields: {
        ...prevFormData.additional_fields,
        [field.id]: value
      }
    }));
  };

  const onDrop = useCallback((acceptedFiles) => {
    setFlyer(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const queryClient = useQueryClient();

  const createEvent = async (newEvent) => {
    const data = {
      tipo_evento: selectedEventType,
      nombre: newEvent.nombre,
      descripcion: newEvent.descripcion,
      monto_estimado: newEvent.montoEstimado,
      fecha_evento: newEvent.fechaDelEvento,
      user: userId,
      additional_fields: newEvent.additional_fields
    };

    if (flyer) {
      const formData = new FormData();
      formData.append('flyer', flyer);

      const response = await axiosInstance.post('v1/events/upload_flyer/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      data.flyer_url = response.data.flyer_url;
    }

    const response = await axiosInstance.post('v1/events/create/', data);
    return response.data; // Devolvemos los datos del evento creado
  };

  const mutation = useMutation({
    mutationFn: createEvent,
    onSuccess: (data) => {
      queryClient.invalidateQueries('events');
      Swal.fire({
        icon: 'success',
        title: 'Evento creado',
        text: 'El evento ha sido creado exitosamente',
      }).then(() => {
        navigate(`/evento/${data.id}`); // Redirigimos al evento creado
      });
    },
    onError: (error) => {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Ocurrió un error al crear el evento: ${error.message}`,
      });
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation.mutate(formData);
  };

  return (
    <div className="flex items-center justify-center h-full p-6 bg-gray-100">
      <div className="w-full max-w-lg p-8 bg-white rounded-lg shadow-lg">
        <h2 className="mb-6 text-3xl font-semibold text-gray-800">Agregar evento</h2>
        <form className="space-y-6" onSubmit={handleSubmit}>
          <input type="hidden" name="user_id" value={userId} />
          <div>
            <label className="block mb-2 font-medium text-gray-600">Tipo de evento</label>
            <select
              name="tipoEvento"
              value={selectedEventType}
              onChange={(e) => setSelectedEventType(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
            >
              <option value="">Seleccione tipo de evento</option>
              {eventTypes.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.nombre}
                </option>
              ))}
            </select>
          </div>
          {selectedEventType && (
            <>
              <div>
                <label className="block mb-2 font-medium text-gray-600">Nombre</label>
                <input
                  type="text"
                  name="nombre"
                  value={formData.nombre}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Ingrese nombre"
                  required
                />
              </div>
              <div>
                <label className="block mb-2 font-medium text-gray-600">Descripción</label>
                <textarea
                  name="descripcion"
                  value={formData.descripcion}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Ingrese una descripción"
                  required
                />
              </div>
              <div>
                <label className="block mb-2 font-medium text-gray-600">Monto estimado</label>
                <input
                  type="text"
                  name="montoEstimado"
                  value={formData.montoEstimado}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Ingrese monto estimado"
                  required
                />
              </div>
              <div>
                <label className="block mb-2 font-medium text-gray-600">Fecha del evento</label>
                <input
                  type="date"
                  name="fechaDelEvento"
                  value={formData.fechaDelEvento}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>
              {additionalFields.map((campo) => (
                <div key={campo.campo.id}>
                  <label className="block mb-2 font-medium text-gray-600">{campo.campo.descripcion}</label>
                  {campo.campo.tipo_campo === 'text' && (
                    <input
                      type="text"
                      name={campo.campo.id} // Usamos el id como nombre para simplificar el envío
                      onChange={(e) => handleFieldChange(e, campo.campo)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder={`Ingrese ${campo.campo.titulo_singula}`}
                      required
                    />
                  )}
                  {campo.campo.tipo_campo === 'date' && (
                    <input
                      type="date"
                      name={campo.campo.id}
                      onChange={(e) => handleFieldChange(e, campo.campo)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      required
                    />
                  )}
                  {campo.campo.tipo_campo === 'time' && (
                    <input
                      type="time"
                      name={campo.campo.id}
                      onChange={(e) => handleFieldChange(e, campo.campo)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      required
                    />
                  )}
                  {campo.campo.tipo_campo === 'amount' && (
                    <input
                      type="number"
                      name={campo.campo.id}
                      onChange={(e) => handleFieldChange(e, campo.campo)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder={`Ingrese ${campo.campo.nombre}`}
                      required
                    />
                  )}
                  {campo.campo.tipo_campo === 'file' && (
                    <div
                      {...getRootProps()}
                      className={`w-full px-3 py-8 bg-gray-50 border-2 border-dashed border-gray-300 rounded-md shadow-sm focus:outline-none ${
                        isDragActive ? 'border-blue-500' : ''
                      }`}
                    >
                      <input {...getInputProps()} name={campo.campo.id} />
                      {isDragActive ? (
                        <p className="text-center text-gray-600">Suelta el archivo aquí...</p>
                      ) : (
                        <p className="text-center text-gray-600">
                          Arrastra y suelta un archivo aquí, o haz clic para seleccionar uno
                        </p>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </>
          )}
          <button
            type="submit"
            className="w-full px-4 py-2 text-white bg-blue-600 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            disabled={!selectedEventType}
          >
            Crear y agregar detalles
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateEvent;

import axiosInstance from "./axiosConfig";

export const getEventTypes = async () => {
    try {
        const response = await axiosInstance.get("/v1/event-types/");
        return response.data;
    } catch (error) {
        return error;
    }
};

export const statsEventTypes = async () => {
    try {
        const response = await axiosInstance.get("/v1/event-types-stats/");
        return response.data;
    } catch (error) {
        return error;
    }
}
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { EyeIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import axiosInstance from '../api/axiosConfig';
import Swal from 'sweetalert2';
import numeral from 'numeral';

const fetchEventsStatus = async () => {
  const { data } = await axiosInstance.get('v1/events/status/');
  return data;
};

const EventStatusList = () => {
  const [page, setPage] = useState(1);
  const { data, error, isLoading, isFetching } = useQuery({
    queryKey: ['eventsStatus', page],
    queryFn: fetchEventsStatus,
  });

  const handleView = (id) => {
    console.log(`Ver detalles del evento con id: ${id}`);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading events</div>;
  }

  return (
    <div className="p-4 sm:p-8">
      <table className="w-full bg-white rounded-lg shadow-md">
        <thead className="bg-gray-300">
          <tr>
            <th className="px-4 py-2 text-left">Nombre del Evento</th>
            <th className="px-4 py-2 text-left">Descripción</th>
            <th className="px-4 py-2 text-left">Monto Estimado</th>
            <th className="px-4 py-2 text-left">Fecha del Evento</th>
            <th className="px-4 py-2 text-left">Solicitado por</th>
            <th className="px-4 py-2 text-left">Último Estado</th>
            <th className="px-4 py-2 text-left">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {data.map((event) => (
            <tr key={event.id} className="border-t">
              <td className="px-4 py-2">{event.nombre}</td>
              <td className="px-4 py-2">{event.descripcion}</td>
              <td className="px-4 py-2">Gs. {numeral(event.monto_estimado).format('0,0').replace(/,/g, '.')}</td>
              <td className="px-4 py-2">{event.fecha_evento}</td>
              <td className="px-4 py-2">{event.nombre_usuario}</td>
              <td className="px-4 py-2">{event.historial[0]?.estado || 'N/A'}</td>
              <td className="flex px-4 py-2 space-x-2">
                <NavLink
                  to={`/estados/${event.id}`}
                  className="flex items-center px-2 py-1 text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none"
                >
                  <EyeIcon className="w-4 h-4 mr-1" />
                  Detalles
                </NavLink>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-between mt-4">
        <button
          onClick={() => setPage((old) => Math.max(old - 1, 1))}
          disabled={page === 1}
          className="px-4 py-2 text-white bg-blue-600 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          Previous
        </button>
        <span className="self-center">{isFetching ? 'Loading...' : `Page ${page}`}</span>
        <button
          onClick={() => setPage((old) => (!data || !data.next ? old : old + 1))}
          disabled={!data || !data.next}
          className="px-4 py-2 text-white bg-blue-600 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default EventStatusList;

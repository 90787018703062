// src/hooks/usePaginatedEvents.js
import { useQuery } from '@tanstack/react-query';
import axiosInstance from '../api/axiosConfig';

const fetchEvents = async (page, tipoEvento) => {
  const { data } = await axiosInstance.get(`v1/events/?page=${page}&tipo_evento=${tipoEvento}`);
  return data;
};

const usePaginatedEvents = (page, tipoEvento) => {
  return useQuery({
    queryKey: ['events', page, tipoEvento],
    queryFn: () => fetchEvents(page, tipoEvento),
    keepPreviousData: true,
  });
};

export default usePaginatedEvents;

// src/api/axiosConfig.js
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL

const axiosInstance = axios.create({
  baseURL: `${apiUrl}/api/`,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
});

axiosInstance.interceptors.request.use(config => {
  const token = localStorage.getItem('access');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(response => {
  return response;
}, async error => {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const refreshToken = localStorage.getItem('refresh');
    const response = await axiosInstance.post('/v1/auth/refresh/', { refresh: refreshToken });
    if (response.status === 200) {
      localStorage.setItem('access', response.data.access);
      axiosInstance.defaults.headers['Authorization'] = `Bearer ${response.data.access}`;
      originalRequest.headers['Authorization'] = `Bearer ${response.data.access}`;
      return axiosInstance(originalRequest);
    } else {
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      useNavigate('/login');
    }
  }
  return Promise.reject(error);
});

export default axiosInstance;

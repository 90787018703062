// src/pages/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../api/axiosConfig';
import LogoLogin from '../assets/img/logo-login.png';
import Swal from 'sweetalert2';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post('/v1/auth/signin/', {
        email,
        password
      });
      localStorage.setItem('access', response.data.access);
      localStorage.setItem('refresh', response.data.refresh);
      Swal.fire({
        icon: 'success',
        title: 'Login exitoso',
        showConfirmButton: false,
        timer: 1500,
      });
      navigate('/dashboard');
    } catch (err) {
      setError('Credenciales inválidas');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bgLogin">
      <div className="flex flex-col items-center justify-center w-full max-w-md p-8 bg-white rounded-lg shadow-md">
        <img
          src={LogoLogin} // Reemplaza con la ruta de tu logo
          alt="Mambo Compliance"
          className="w-full mb-6"
        />
        <h4 className="mb-4 text-2xl font-bold text-gray-800">Favor, complete los datos para avanzar</h4>
        {error && <p className="mb-4 text-red-500">{error}</p>}
        <form className="w-full space-y-6" onSubmit={handleSubmit}>
          <div>
            <label className="block mb-2 font-medium text-gray-600">Correo electrónico</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Ejemplo: usuario@ejemplo.com"
              required
            />
          </div>
          <div>
            <label className="block mb-2 font-medium text-gray-600">Contraseña</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Ingrese contraseña"
              required
            />
          </div>
          <button
            type="submit"
            className="flex items-center justify-center w-full px-4 py-2 font-medium text-white bg-blue-600 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            <span>Ingresar</span>
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import UserName from './UserName';
import axiosInstance from '../api/axiosConfig';
import numeral from 'numeral';
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';
import { PaperClipIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import Tooltip from './Tooltip';

const fetchEventStatus = async (eventId) => {
  const { data } = await axiosInstance.get(`v1/events/status/${eventId}/`);
  return data;
};

const fetchEvidences = async (eventId) => {
  const { data } = await axiosInstance.get(`v1/events/${eventId}/evidencias/`);
  return data;
};

const BudgetStatus = () => {
  const { eventId } = useParams();
  const [currentTab, setCurrentTab] = useState('prevItems');
  
  const { data: event, isLoading, error } = useQuery({
    queryKey: ['event', eventId],
    queryFn: () => fetchEventStatus(eventId),
  });

  const { data: evidences } = useQuery({
    queryKey: ['evidences', eventId],
    queryFn: () => fetchEvidences(eventId),
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="p-8">
      <h1 className="mb-6 text-3xl font-semibold">Estado del presupuesto</h1>
      <div className="p-6 bg-white rounded-lg shadow-md">
        <EventHeader event={event} />
        <EventTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
        <div className="flex">
          <div className="w-2/3 pr-4">
            {currentTab === 'prevItems' && event.tipo_sometimiento.map((presupuesto) => (
              <BudgetTable key={presupuesto.id} presupuesto={presupuesto} />
            ))}
            {currentTab === 'evidences' && (
              <EvidenceList evidences={evidences} />
            )}
          </div>
          <div className="w-1/3 pl-4">
            <EventStates historial={event.historial} />
          </div>
        </div>
      </div>
    </div>
  );
};

const EventHeader = ({ event }) => (
  <div className="flex items-center justify-between mb-6 status-header">
    <div>
      <h2 className="text-xl font-bold">{event.nombre}</h2>
      <p className="text-gray-600">Evento {event.descripcion}</p>
      <p className="text-gray-600 status-fecha">{event.fecha_evento}</p>
      <p className="text-gray-600 status-monto">Gs. {numeral(event.monto_estimado).format('0,0').replace(/,/g, '.')}</p>
    </div>
    <div>
      <span className="px-3 py-1 text-green-800 bg-green-200 rounded-full">Aceptado</span>
    </div>
  </div>
);

const EventTabs = ({ currentTab, setCurrentTab }) => (
  <div className="flex mb-4 border-b border-gray-300">
    <button
      className={`tab-status pb-2 ${currentTab === 'prevItems' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-400'}`}
      onClick={() => setCurrentTab('prevItems')}
    >
      Items previos al evento 
    </button>
    <button
      className={`tab-status pb-2 ${currentTab === 'evidences' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-400'}`}
      onClick={() => setCurrentTab('evidences')}
    >
      Evidencias del evento
    </button>
  </div>
);

const BudgetTable = ({ presupuesto }) => (
  <div className="mb-6">
    <h3 className="text-lg font-semibold">{presupuesto.tipo_sometimiento_nombre}</h3>
    <div className="p-4 rounded-lg shadow-inner bg-gray-50">
      <table className="w-full">
        <thead>
          <tr>
            <th className="text-left">Título del pedido</th>
            <th className="text-left">Monto</th>
            <th className="text-left">Proveedor</th>
            <th className="text-left">Solicitado por</th>
          </tr>
        </thead>
        <tbody>
          {presupuesto.sometimientos.map((item, itemIndex) => (
            <tr key={itemIndex} className="border-t">
              <td className="py-2">{item.nombre}</td>
              <td>Gs. {numeral(item.monto).format('0,0').replace(/,/g, '.')}</td>
              <td>{item.proveedor}</td>
              <td><UserName userId={item.solicitado_por} /></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

const EventStates = ({ historial }) => (
  <div>
    <h3 className="text-lg font-semibold">Estados</h3>
    <div className="p-4 rounded-lg shadow-inner bg-gray-50">
      <div className="h-64 overflow-y-auto">
        <ul className="space-y-4">
          {historial
            .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
            .map((estado, index) => (
              <EventState
                key={index}
                number={index + 1}
                status={estado.estado}
                sometimiento={estado.sometimiento_nombre}
                comentarios={estado.comentarios}
                user={estado.nombre_usuario}
                date={estado.created_at}
                color={getStatusColor(estado.estado)}
              />
            ))}
        </ul>
      </div>
    </div>
  </div>
);

const EvidenceList = ({ evidences }) => (
  <div>
    <h3 className="text-lg font-semibold">Evidencias del Evento</h3>
    {evidences && evidences.map((evidence) => (
      <div key={evidence.id} className="mb-4">
        <p><strong>Descripción:</strong> {evidence.descripcion}</p>
        <ul className="space-y-2">
          {evidence.archivos.map((archivo) => (
            <li key={archivo.id} className="flex items-center">
              <PaperClipIcon className="w-5 h-5 text-gray-600" />
              <a href={`${axiosInstance.defaults.baseURL}${archivo.archivo}`} target="_blank" rel="noopener noreferrer" className="ml-2 text-blue-500">
                {archivo.archivo.split('/').pop()}
              </a>
            </li>
          ))}
        </ul>
      </div>
    ))}
  </div>
);

const EventState = ({ number, status, user, sometimiento, comentarios, date, color }) => (
  <li className="flex items-center">
    <div>
      <span className={`w-8 h-8 bg-${color}-500 text-white flex items-center justify-center rounded-full mr-3`}>{number}</span>
    </div>
    <div>
      <p className={` status-estado font-semibold text-${color}-500`}>{status}
      
        <Tooltip position='top' text={sometimiento}>
          <InformationCircleIcon className="w-4 h-4 ml-2" />
        </Tooltip>
      </p>
      {comentarios && <p className="text-gray-800 status-comment">{comentarios}</p>}
      {user && <p className="text-gray-600 status-user-update"><strong>Actualizado por:</strong> {user}</p>}
      {date && <p className="text-gray-400 status-fecha-lista">{formatDistanceToNow(new Date(date), { addSuffix: true, locale: es })}</p>} 
    </div>
  </li>
);

const getStatusColor = (status) => {
  switch (status) {
    case 'APROBADO':
      return 'green';
    case 'PENDIENTE':
      return 'yellow';
    case 'BORRADOR':
      return 'gray';
    case 'RECHAZADO':
      return 'red';
    default:
      return 'gray';
  }
};

export default BudgetStatus;

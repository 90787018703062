// src/layouts/ProtectedLayout.js
import React from 'react';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';

const ProtectedLayout = ({ children }) => {
  return (
    <div className="flex  min-h-screen">
      <Sidebar />
      <div className="flex flex-col flex-1">
        <Header />
        <main className="flex-1 p-4 bg-gray-200">
          {children}
        </main>
      </div>
    </div>
  );
};

export default ProtectedLayout;

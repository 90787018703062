import { useQuery } from '@tanstack/react-query';
import axiosInstance from '../api/axiosConfig';

const fetchUserProfile = async () => {
  const { data } = await axiosInstance.get('v1/auth/me/');
  return data;
};

const useUserProfile = () => {
  return useQuery({
    queryKey: 'userProfile',
    queryFn: fetchUserProfile,
    keepPreviousData: true,
  });
};

export default useUserProfile;
// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ProtectedLayout from './layouts/ProtectedLayout';
import Dashboard from './components/Dashboard';
import CreateEvent from './components/CreateEvent';
import EventList from './components/EventList';
import EventDetails from './components/EventDetails';
import EventApprovalDetails from './components/EventApprovalDetails';
import BudgetStatus from './components/BudgetStatus';
import BudgetStatuses from './components/StatusesList';

import Login from './pages/Login';
import Logout from './components/Logout';
import PrivateRoute from './components/PrivateRoute';


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <ProtectedLayout>
                <Dashboard />
              </ProtectedLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/crear-evento"
          element={
            <PrivateRoute>
              <ProtectedLayout>
                <CreateEvent />
              </ProtectedLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/tipo-eventos/:tipoEvento"
          element={
            <PrivateRoute>
              <ProtectedLayout>
                <EventList />
              </ProtectedLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/evento/:eventId"
          element={
            <PrivateRoute>
              <ProtectedLayout>
                <EventDetails />
              </ProtectedLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/aprobar-evento/:eventId"
          element={
            <PrivateRoute>
              <ProtectedLayout>
                <EventApprovalDetails />
              </ProtectedLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/estados"
          element={
            <PrivateRoute>
              <ProtectedLayout>
                <BudgetStatuses />
              </ProtectedLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/estados/:eventId"
          element={
            <PrivateRoute>
              <ProtectedLayout>
                <BudgetStatus />
              </ProtectedLayout>
            </PrivateRoute>
          }
        />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </Router>
  );
};

export default App;

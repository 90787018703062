import React, { useState, useEffect } from 'react';
import { useParams} from 'react-router-dom';
import Modal from 'react-modal';
import { EyeIcon, PlusIcon, CheckIcon, PencilIcon, TrashIcon, PaperClipIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import useEventDetails from '../hooks/useEventDetails';
import { useDropzone } from 'react-dropzone';
import { jwtDecode } from 'jwt-decode';
import UserName from './UserName';
import axiosInstance from '../api/axiosConfig';
import numeral from 'numeral';
import Swal from 'sweetalert2';
import Tooltip from './Tooltip';
import useUserProfile from '../hooks/useUserProfile';

const EventDetails = () => {
  const { eventId } = useParams();
  const { data: event } = useEventDetails(eventId);
  const { data: userProfile } = useUserProfile();
  const [currentTab, setCurrentTab] = useState('prevItems');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [currentPresupuesto, setCurrentPresupuesto] = useState(null);
  const [newItem, setNewItem] = useState({ nombre: '', monto: '', proveedor: '', sugerido: false, presupuesto: null, solicitado_por: '' });
  const [editItem, setEditItem] = useState(null);
  const [modalState, setModalState] = useState({ isOpen: false, modalType: '' });
  const [submissionData, setSubmissionData] = useState({});
  const [aprobacionTiposSometimiento, setAprobacionTiposSometimiento] = useState([]);
  const [displayedTiposSometimiento, setDisplayedTiposSometimiento] = useState([]);
  const [tipoSometimientos, setTipoSometimientos] = useState([]);
  const [evidences, setEvidences] = useState([]);
  const [presupuestoFile, setPresupuestoFile] = useState(null); // Estado para almacenar archivo de presupuesto
  const [isPostItemsTabEnabled, setIsPostItemsTabEnabled] = useState(false);

  const token = localStorage.getItem('access');
  const decodedToken = jwtDecode(token);
  const userId = parseInt(decodedToken.user_id, 10);

  const isApprover = userProfile?.categorias?.some(categoria => categoria.nombre === 'Aprobador');

  useEffect(() => {
    if (event) {
      fetchSubmissionData(event.tipo_sometimiento.map(p => p.id));
      fetchTipoSometimientos(event.tipo_evento);
      fetchUploadedFiles();
      fetchEvidences();
      // Habilitar la pestaña "Items Posteriores al evento" solo si la fecha actual es un día después o más tarde
      const eventDate = new Date(event.fecha_evento);
      const currentDate = new Date();

      if (currentDate > eventDate) {
        setIsPostItemsTabEnabled(true);
      }
      console.log(event);
    }
  }, [event]);

  const fetchTipoSometimientos = async (tipoEventoId) => {
    try {
      const response = await axiosInstance.get(`v1/submission_types/${tipoEventoId}/`);
      setTipoSometimientos(response.data);
    } catch (error) {
      console.error('Error fetching tipo sometimientos:', error);
    }
  };

  const fetchUploadedFiles = async () => {
    try {
      const response = await axiosInstance.get(`v1/events/${eventId}/evidencias/`);
      setUploadedFiles(response.data);
    } catch (error) {
      console.error('Error fetching uploaded files:', error);
    }
  };

  const fetchEvidences = async () => {
    try {
      const response = await axiosInstance.get(`/v1/events/${eventId}/evidencias/`);
      setEvidences(response.data);
    } catch (error) {
      console.error('Error fetching evidences:', error);
    }
  };

  const fetchSubmissionData = async (tipoSometimientoIds) => {
    try {
      const promises = tipoSometimientoIds.map(id => axiosInstance.get(`v1/submission/?evento_id=${eventId}&tipo_sometimiento_id=${id}`));
      const results = await Promise.all(promises);
      const data = results.reduce((acc, result, index) => {
        acc[tipoSometimientoIds[index]] = result.data;
        return acc;
      }, {});
      setSubmissionData(data);

      const displayedIds = tipoSometimientoIds.filter(id => data[id].length > 0);
      setDisplayedTiposSometimiento(displayedIds);
    } catch (error) {
      console.error('Error fetching submission data:', error);
    }
  };

  const openModal = (type, item = null) => {
    if (type === 'editItem') {
      setEditItem({
        ...item,
        presupuesto: item.presupuesto ? { name: item.presupuesto.split('/').pop() } : null,
      });
    }
    setModalState({ isOpen: true, modalType: type });
  };

  const closeModal = () => {
    setModalState({ isOpen: false, modalType: '' });
    setCurrentPresupuesto(null);
    setEditItem(null);
    setPresupuestoFile(null); // Limpiar archivo de presupuesto al cerrar el modal
  };

  const handleAddItem = (presupuesto) => {
    if (submissionData[presupuesto.id]?.some(item => item.historial[0]?.estado === 'PENDIENTE')) {
      Swal.fire({
        icon: 'warning',
        title: 'No se puede agregar',
        text: 'El tipo de sometimiento ya está en estado PENDIENTE y no se pueden agregar más ítems.',
      });
      return;
    }
    if (submissionData[presupuesto.id]?.some(item => item.historial[0]?.estado === 'APROBADO')) {
      Swal.fire({
        icon: 'warning',
        title: 'No se puede agregar',
        text: 'El tipo de sometimiento ya está en estado APROBADO y no se pueden agregar más ítems.',
      });
      return;
    }
    setCurrentPresupuesto(presupuesto);
    setNewItem({ nombre: '', monto: '', proveedor: '', sugerido: false, presupuesto: null, solicitado_por: userId });
    openModal('addItem');
  };

  const handleNewItemChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewItem((prevItem) => ({
      ...prevItem,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleEditItemChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditItem((prevItem) => ({
      ...prevItem,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleAddNewItem = async () => {
    if (!presupuestoFile) {
      Swal.fire({
        icon: 'error',
        title: 'Archivo requerido',
        text: 'Por favor, adjunta un archivo de presupuesto.',
      });
      return;
    }
    const itemData = new FormData();
    itemData.append('nombre', newItem.nombre);
    itemData.append('monto', newItem.monto);
    itemData.append('proveedor', newItem.proveedor);
    itemData.append('sugerido', newItem.sugerido);
    itemData.append('solicitado_por', newItem.solicitado_por);
    itemData.append('evento', eventId);
    itemData.append('tipo_sometimiento', currentPresupuesto.id);
    itemData.append('presupuesto', presupuestoFile);

    try {
      await axiosInstance.post('v1/submission/create/', itemData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      Swal.fire({
        icon: 'success',
        title: 'Item agregado con éxito',
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
      }).then(() => {
        fetchSubmissionData([currentPresupuesto.id]);
        closeModal();
        window.location.reload();
      });
    } catch (error) {
      console.error('Error al agregar el item:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error al agregar el item',
        text: error.message,
      });
    }
  };

  const handleUpdateItem = async () => {
    const itemData = new FormData();
    itemData.append('nombre', editItem.nombre);
    itemData.append('monto', editItem.monto);
    itemData.append('proveedor', editItem.proveedor);
    itemData.append('sugerido', editItem.sugerido);
    itemData.append('solicitado_por', editItem.solicitado_por);
    itemData.append('evento', eventId);
    itemData.append('tipo_sometimiento', editItem.tipo_sometimiento);
    if (presupuestoFile) {
      itemData.append('presupuesto', presupuestoFile);
    }

    try {
      await axiosInstance.put(`v1/submission/update/${editItem.id}/`, itemData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      Swal.fire({
        icon: 'success',
        title: 'Item actualizado con éxito',
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
      }).then(() => {
        fetchSubmissionData([editItem.tipo_sometimiento]);
        closeModal();
        window.location.reload();
      });
    } catch (error) {
      console.error('Error al actualizar el item:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error al actualizar el item',
        text: error.message,
      });
    }
  };

  const handleSendForApproval = async () => {
    if (aprobacionTiposSometimiento.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Tipos de Sometimiento requeridos',
        text: 'Por favor, selecciona al menos un tipo de sometimiento antes de enviar el evento a aprobación.',
      });
      return;
    }

    try {
      await axiosInstance.post('/v1/notifications/create/', {
        evento_id: eventId,
        tipos_sometimiento: aprobacionTiposSometimiento.map(item => item.id),
        mensaje: 'Por favor, revisa y aprueba un presupuesto para este evento.',
      });

      Swal.fire({
        icon: 'success',
        title: 'Enviado para aprobación',
        text: 'El evento ha sido enviado para aprobación.',
      }).then(() => {
        closeModal();
        window.location.reload();
      });
    } catch (error) {
      console.error('Error sending for approval:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response?.data?.error || 'Ocurrió un error al enviar el evento para aprobación.',
      });
    }
  };

  // Separar onDrop para presupuesto
  const onDropPresupuesto = (acceptedFiles) => {
    setPresupuestoFile(acceptedFiles[0]);
  };

  // onDrop para evidencias
  const onDropEvidencias = (acceptedFiles) => {
    setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
  };

  const { getRootProps: getPresupuestoRootProps, getInputProps: getPresupuestoInputProps, isDragActive: isPresupuestoDragActive } = useDropzone({
    onDrop: onDropPresupuesto,
    accept: '.pdf,.doc,.docx', // Puedes ajustar los tipos aceptados según sea necesario
  });

  const { getRootProps: getEvidenciasRootProps, getInputProps: getEvidenciasInputProps, isDragActive: isEvidenciasDragActive } = useDropzone({
    onDrop: onDropEvidencias,
    accept: 'image/*, .pdf, .doc, .docx',
  });

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleCheckboxChange = (e, sometimiento, type) => {
    if (type === 'display') {
      if (e.target.checked) {
        setDisplayedTiposSometimiento([...displayedTiposSometimiento, sometimiento.id]);
      } else {
        setDisplayedTiposSometimiento(displayedTiposSometimiento.filter((id) => id !== sometimiento.id));
      }
    } else if (type === 'approval') {
      if (e.target.checked) {
        setAprobacionTiposSometimiento([...aprobacionTiposSometimiento, sometimiento]);
      } else {
        setAprobacionTiposSometimiento(aprobacionTiposSometimiento.filter((item) => item.id !== sometimiento.id));
      }
    }
  };

  const isItemSugerido = (presupuestoId) => {
    const presupuesto = submissionData[presupuestoId];
    if (!presupuesto) return false;
    return presupuesto.some((item) => item.sugerido);
  };

  const handleUploadEvidence = async () => {
    const { value: description } = await Swal.fire({
      title: 'Descripción de la Evidencia',
      input: 'text',
      inputLabel: 'Ingrese una descripción para la evidencia',
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'La descripción es requerida';
        }
      },
    });

    if (description) {
      const formData = new FormData();
      formData.append('evento', eventId);
      formData.append('descripcion', description);
      uploadedFiles.forEach((file) => {
        formData.append('archivos', file);
      });

      try {
        await axiosInstance.post(`/v1/events/${eventId}/subir/evidencias/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        Swal.fire({
          icon: 'success',
          title: 'Evidencia enviada',
          text: 'La evidencia ha sido enviada con éxito.',
        }).then(() => {
          fetchUploadedFiles();
          setUploadedFiles([]); // Clear the uploaded files after submission
          setCurrentTab('postItems');  // Cambia al tab de evidencias
          window.location.reload();
        });
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Ocurrió un error al enviar la evidencia.',
        });
      }
    }
  };

  const renderTabContent = () => {
    if (currentTab === 'prevItems') {
      return (
        <div className="space-y-8">
          {displayedTiposSometimiento.map((tipoSometimientoId) => {
            const presupuesto = tipoSometimientos.find((ts) => ts.tipo_sometimiento.id === tipoSometimientoId)?.tipo_sometimiento;
            if (!presupuesto) return null;

            return (
              <div key={presupuesto.id} className="p-4 bg-white rounded-lg shadow-md">
                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-xl font-bold">{presupuesto.nombre}</h2>
                  {presupuesto.historial?.[0]?.estado !== 'PENDIENTE' && presupuesto.historial?.[0]?.estado !== 'APROBADO' && (
                    <button onClick={() => handleAddItem(presupuesto)} className="flex items-center text-blue-500">
                      <PlusIcon className="w-5 h-5 mr-1" />
                      Agregar
                    </button>
                  )}
                </div>
                <table className="w-full bg-white rounded-lg">
                  <thead className="bg-gray-200">
                    <tr>
                      <th className="px-4 py-2 text-left">Título del pedido</th>
                      <th className="px-4 py-2 text-left">Monto</th>
                      <th className="px-4 py-2 text-left">Proveedor</th>
                      <th className="px-4 py-2 text-left">Solicitado por</th>
                      <th className="px-4 py-2 text-left">Sugerido</th>
                      <th className="px-4 py-2 text-left">Adjunto</th>
                      <th className="px-4 py-2 text-left">Estado</th>
                      <th className="px-4 py-2 text-left">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {submissionData[presupuesto.id]?.map((item, itemIndex) => (
                      <tr key={itemIndex} className="border-t">
                        <td className="px-4 py-2">{item.nombre}</td>
                        <td className="px-4 py-2">Gs. {numeral(item.monto).format('0,0').replace(/,/g, '.')}</td>
                        <td className="px-4 py-2">{item.proveedor}</td>
                        <td className="flex items-center px-4 py-2">
                          <UserName userId={item.solicitado_por} />
                        </td>
                        <td className="px-4 py-2">{item.sugerido ? 'Si' : ''}</td>
                        <td className="px-4 py-2">
                          {item.presupuesto ? (
                            <a href={`${apiUrl}${item.presupuesto}`} target="_blank" rel="noopener noreferrer">
                              <PaperClipIcon className="w-4 h-4 text-gray-500" />
                            </a>
                          ) : (
                            ''
                          )}
                        </td>
                        <td className="px-4 py-2">
                          {item.estado || (item.historial && item.historial.length > 0 ? item.historial[0].estado : 'NO GUARDADO')}
                          <Tooltip position='top' text={item.historial && item.historial.length > 0 ? item.historial[0].comentarios : 'Sin comentarios'}>
                            <InformationCircleIcon className="w-3 h-3 ml-2" />
                          </Tooltip>
                        </td>
                        <td className="flex px-4 py-2 space-x-2">
                          {item.historial?.[0]?.estado !== 'PENDIENTE' && item.historial?.[0]?.estado !== 'APROBADO' && item.historial?.[0]?.estado !== 'DESCARTADO' && (
                            <button
                              onClick={() => openModal('editItem', item)}
                              className="flex items-center px-2 py-1 text-white bg-green-500 rounded hover:bg-green-700 focus:outline-none"
                            >
                              <PencilIcon className="w-4 h-4 mr-1" />
                              Editar
                            </button>
                            
                          )}
                          {!item.id && (
                            <button className="flex items-center px-2 py-1 text-white bg-red-500 rounded hover:bg-red-700 focus:outline-none">
                              <TrashIcon className="w-4 h-4 mr-1" />
                              Eliminar
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            );
          })}
          <div className="flex justify-end mt-8 space-x-4">
            {!isApprover && (
              <button
                onClick={() => openModal('sendForApproval')}
                className="flex items-center px-4 py-2 text-white bg-yellow-600 rounded-md shadow-md hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
              >
                <CheckIcon className="w-5 h-5 mr-1" />
                Someter a aprobación
              </button>
            )}
          </div>
        </div>
      );
    } else if (currentTab === 'postItems') {
      return (
        <div className="space-y-4">
          <div>
            <div
              {...getEvidenciasRootProps()}
              className={`w-full px-3 py-8 bg-gray-50 border-2 border-dashed border-gray-300 rounded-md shadow-sm focus:outline-none ${
                isEvidenciasDragActive ? 'border-blue-500' : ''
              }`}
            >
              <input {...getEvidenciasInputProps()} />
              {isEvidenciasDragActive ? (
                <p className="text-center text-gray-600">Suelta los archivos aquí...</p>
              ) : (
                <p className="text-center text-gray-600">Arrastra y suelta archivos aquí, o haz clic para seleccionar archivos</p>
              )}
            </div>
          </div>
          <h3 className="text-lg font-semibold">Archivos Subidos</h3>
          <ul className="space-y-2">
            {uploadedFiles.map((file, index) => (
              <li key={index} className="flex items-center">
                <PaperClipIcon className="w-5 h-5 text-gray-600" />
                <span className="ml-2">{file.name}</span>
              </li>
            ))}
          </ul>
          <div>
            <h3 className="text-lg font-semibold">Evidencias</h3>
            {evidences.map((evidence) => (
              <div key={evidence.id} className="mb-4">
                <p><strong>Descripción:</strong> {evidence.descripcion}</p>
                <ul className="space-y-2">
                  {evidence.archivos.map((archivo) => (
                    <li key={archivo.id} className="flex items-center">
                      <PaperClipIcon className="w-5 h-5 text-gray-600" />
                      <a href={`${apiUrl}${archivo.archivo}`} target="_blank" rel="noopener noreferrer" className="ml-2 text-blue-500">
                        {archivo.archivo.split('/').pop()}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="mt-4">
            <button
              onClick={handleUploadEvidence}
              className="flex items-center px-4 py-2 text-white bg-blue-600 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              <CheckIcon className="w-5 h-5 mr-1" />
              Enviar Archivos como Evidencia
            </button>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="p-4 sm:p-8">
      {event && (
        <>
          <div className="flex items-center justify-between mb-4">
            <h1 className="text-2xl font-bold">Evento: {event.nombre}</h1>
            <div className="flex items-center space-x-4">
              <span>{event.fecha_evento}</span>
              <span>Gs. {numeral(event.monto_estimado).format('0,0').replace(/,/g, '.')}</span>
              <button onClick={() => openModal('viewDetails')} className="flex items-center text-blue-500">
                <EyeIcon className="w-5 h-5 mr-1" />
                ver detalles
              </button>
              <button onClick={() => openModal('selectTiposSometimiento')} className="flex items-center text-blue-500">
                <PlusIcon className="w-5 h-5 mr-1" />
                Seleccionar Tipos de Sometimiento
              </button>
            </div>
          </div>

          <Modal
            isOpen={modalState.isOpen && modalState.modalType === 'viewDetails'}
            onRequestClose={closeModal}
            contentLabel="Detalles del Evento"
            className="modal"
            overlayClassName="overlay"
          >
            <h2 className="text-xl font-bold">Detalles del Evento</h2>
            <div className="mt-4 space-y-2">
              <p><strong>Nombre:</strong> {event.nombre}</p>
              <p><strong>Descripción:</strong> {event.descripcion}</p>
              <p><strong>Fecha del Evento:</strong> {event.fecha_evento}</p>
              <p><strong>Monto Estimado:</strong> Gs. {numeral(event.monto_estimado).format('0,0').replace(/,/g, '.')}</p>
              <p><strong>Tipo de Evento:</strong> {event.tipo_evento_nombre}</p>
            </div>
            <button onClick={closeModal} className="px-4 py-2 mt-4 text-white bg-blue-600 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
              Cerrar
            </button>
          </Modal>

          <Modal
            isOpen={modalState.isOpen && modalState.modalType === 'selectTiposSometimiento'}
            onRequestClose={closeModal}
            contentLabel="Seleccionar Tipos de Sometimiento"
            className="modal"
            overlayClassName="overlay"
          >
            <h2 className="text-xl font-bold">Seleccionar Tipos de Sometimiento</h2>
            <div className="mt-4 space-y-2">
              {tipoSometimientos.map((sometimiento) => (
                <div key={sometimiento.tipo_sometimiento.id} className="flex items-center">
                  <input
                    type="checkbox"
                    id={`sometimiento-${sometimiento.tipo_sometimiento.id}`}
                    checked={displayedTiposSometimiento.includes(sometimiento.tipo_sometimiento.id)}
                    onChange={(e) => handleCheckboxChange(e, sometimiento.tipo_sometimiento, 'display')}
                    className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                  />
                  <label htmlFor={`sometimiento-${sometimiento.tipo_sometimiento.id}`} className="ml-2 text-gray-700">{sometimiento.tipo_sometimiento.nombre}</label>
                </div>
              ))}
            </div>
            <button onClick={closeModal} className="px-4 py-2 mt-4 text-white bg-blue-600 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
              Cerrar
            </button>
          </Modal>

          <Modal
            isOpen={modalState.isOpen && modalState.modalType === 'sendForApproval'}
            onRequestClose={closeModal}
            contentLabel="Someter a Aprobación"
            className="modal"
            overlayClassName="overlay"
          >
            <h2 className="text-xl font-bold">Someter a Aprobación</h2>
            <div className="mt-4 space-y-2">
              {tipoSometimientos.map((sometimiento) => (
                <div key={sometimiento.tipo_sometimiento.id} className="flex items-center">
                  <input
                    type="checkbox"
                    id={`aprobacion-sometimiento-${sometimiento.tipo_sometimiento.id}`}
                    checked={aprobacionTiposSometimiento.some((item) => item.id === sometimiento.tipo_sometimiento.id)}
                    onChange={(e) => handleCheckboxChange(e, sometimiento.tipo_sometimiento, 'approval')}
                    className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                    disabled={submissionData[sometimiento.tipo_sometimiento.id]?.some(submission => submission.historial[0]?.estado !== 'BORRADOR' )}
                  />
                  <label htmlFor={`aprobacion-sometimiento-${sometimiento.id}`} className="ml-2 text-gray-700">{sometimiento.tipo_sometimiento.nombre}</label>
                </div>
              ))}
            </div>
            <button onClick={handleSendForApproval} className="px-4 py-2 mt-4 text-white bg-blue-600 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
              Enviar para aprobación
            </button>
            <button onClick={closeModal} className="px-4 py-2 mt-4 ml-2 text-white bg-gray-600 rounded-md shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
              Cancelar
            </button>
          </Modal>

          <div className="flex items-center justify-between mb-6">
            <nav className="flex space-x-4">
              <button
                onClick={() => setCurrentTab('prevItems')}
                className={`pb-2 ${currentTab === 'prevItems' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-400'}`}
              >
                Items previos al evento
              </button>
              <button
                onClick={() => isPostItemsTabEnabled && setCurrentTab('postItems')}
                className={`pb-2 ${currentTab === 'postItems' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-400'} ${!isPostItemsTabEnabled ? 'cursor-not-allowed opacity-50' : ''}`}
                disabled={!isPostItemsTabEnabled}
              >
                Items Posteriores al evento
              </button>
            </nav>
          </div>

          {renderTabContent()}

          
        </>
      )}

      <Modal
        isOpen={modalState.isOpen && modalState.modalType === 'addItem'}
        onRequestClose={closeModal}
        contentLabel="Agregar Item"
        className="modal"
        overlayClassName="overlay"
      >
        <h2 className="text-xl font-bold">Agregar Item a {currentPresupuesto ? currentPresupuesto.nombre : ''}</h2>
        <div className="mt-4 space-y-2">
          <div>
            <label className="block mb-2 font-medium text-gray-600">Título del pedido</label>
            <input
              type="text"
              name="nombre"
              value={newItem.nombre}
              onChange={handleNewItemChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div>
            <label className="block mb-2 font-medium text-gray-600">Monto</label>
            <input
              type="text"
              name="monto"
              value={newItem.monto}
              onChange={handleNewItemChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div>
            <label className="block mb-2 font-medium text-gray-600">Proveedor</label>
            <input
              type="text"
              name="proveedor"
              value={newItem.proveedor}
              onChange={handleNewItemChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div>
            <label className="block mb-2 font-medium text-gray-600">Sugerido</label>
            <input
              type="checkbox"
              name="sugerido"
              checked={newItem.sugerido}
              onChange={handleNewItemChange}
              className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
              disabled={isItemSugerido(currentPresupuesto?.id)}
            />
          </div>
          <div>
            <label className="block mb-2 font-medium text-gray-600">Presupuesto</label>
            <div
              {...getPresupuestoRootProps()}
              className={`w-full px-3 py-8 bg-gray-50 border-2 border-dashed border-gray-300 rounded-md shadow-sm focus:outline-none ${
                isPresupuestoDragActive ? 'border-blue-500' : ''
              }`}
            >
              <input {...getPresupuestoInputProps()} />
              {isPresupuestoDragActive ? (
                <p className="text-center text-gray-600">Suelta el archivo aquí...</p>
              ) : (
                <p className="text-center text-gray-600">Arrastra y suelta un archivo aquí, o haz clic para seleccionar uno</p>
              )}
            </div>
            {presupuestoFile && (
              <p className="mt-2 text-sm text-gray-600">{presupuestoFile.name}</p>
            )}
          </div>
        </div>
        <button onClick={handleAddNewItem} className="px-4 py-2 mt-4 text-white bg-blue-600 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
          Agregar
        </button>
        <button onClick={closeModal} className="px-4 py-2 mt-4 ml-2 text-white bg-gray-600 rounded-md shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
          Cancelar
        </button>
      </Modal>

      <Modal
        isOpen={modalState.isOpen && modalState.modalType === 'editItem'}
        onRequestClose={closeModal}
        contentLabel="Editar Item"
        className="modal"
        overlayClassName="overlay"
      >
        <h2 className="text-xl font-bold">Editar Item</h2>
        <div className="mt-4 space-y-2">
          <div>
            <label className="block mb-2 font-medium text-gray-600">Título del pedido</label>
            <input
              type="text"
              name="nombre"
              value={editItem?.nombre || ''}
              onChange={handleEditItemChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div>
            <label className="block mb-2 font-medium text-gray-600">Monto</label>
            <input
              type="text"
              name="monto"
              value={editItem?.monto || ''}
              onChange={handleEditItemChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div>
            <label className="block mb-2 font-medium text-gray-600">Proveedor</label>
            <input
              type="text"
              name="proveedor"
              value={editItem?.proveedor || ''}
              onChange={handleEditItemChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div>
            <label className="block mb-2 font-medium text-gray-600">Sugerido</label>
            <input
              type="checkbox"
              name="sugerido"
              checked={editItem?.sugerido || false}
              onChange={handleEditItemChange}
              className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
              disabled={isItemSugerido(currentPresupuesto)}
            />
          </div>
          <div>
            <label className="block mb-2 font-medium text-gray-600">Presupuesto</label>
            <div
              {...getPresupuestoRootProps()}
              className={`w-full px-3 py-8 bg-gray-50 border-2 border-dashed border-gray-300 rounded-md shadow-sm focus:outline-none ${
                isPresupuestoDragActive ? 'border-blue-500' : ''
              }`}
            >
              <input {...getPresupuestoInputProps()} />
              {isPresupuestoDragActive ? (
                <p className="text-center text-gray-600">Suelta el archivo aquí...</p>
              ) : (
                <p className="text-center text-gray-600">Arrastra y suelta un archivo aquí, o haz clic para seleccionar uno</p>
              )}
            </div>
            {presupuestoFile && (
              <p className="mt-2 text-sm text-gray-600">{presupuestoFile.name}</p>
            )}
          </div>
        </div>
        <button onClick={handleUpdateItem} className="px-4 py-2 mt-4 text-white bg-blue-600 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
          Guardar
        </button>
        <button onClick={closeModal} className="px-4 py-2 mt-4 ml-2 text-white bg-gray-600 rounded-md shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
          Cancelar
        </button>
      </Modal>
    </div>
  );
};

export default EventDetails;
